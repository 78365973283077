export const snacks = [
  // {
  //   "image": "https://balancegrille.s3.amazonaws.com/menu-images/snacks/shishito-peppers-landscape.webp",
  //   "title": "shishito peppers",
  //   "price": "$6",
  //   "flavorTags": [
  //     { "tag": "Citrusy" },
  //     { "tag": "Salty" },
  //     { "tag": "Savory" },
  //     { "tag": "Spicy 2/5" },
  //   ],
  //   "description": "Restaurant Week Toledo 2023 Special! Classic Japanese mild peppers flash-fried and blistered, tossed in our signature blend of spices.",
  //   "dietary": [
  //     { "type": "vegan", "title": "Vegan" },
  //     { "type": "gluten-free", "title": "Gluten Free", }
  //   ],
  // },

  // {
  //   "image": "https://balancegrille.com/wp-content/uploads/2023/10/Korean-Corndog-Horizontal.png",
  //   "title": "korean cheesedog",
  //   "price": "$3.5",
  //   // "flavorTags": [
  //   //   { "tag": "Lightly Spiced" },
  //   //   { "tag": "Sweet" }
  //   // ],
  //   "description": "Only available for a limited time, our Korean Cheesedog is our take on a very recognized Korean street food. Made with cheese (no meat) and coated cornmeal batter plus panko crumbs to provide the ideal texture. Add your favorite sauce (our favorite is Sweet & Sour or Korean Gochujang), or enjoy it plain.",
  //   // "descriptionNotes": "",
  //   "dietary": [
  //     { "type": "vegetarian", "title": "Vegetarian" },
  //   ],
  //   // "nutrition": [
  //   //   { "value": "310", "unit": "Calories" },
  //   //   { "value": "5g", "unit": "Protein" },
  //   //   { "value": "13g", "unit": "Total Carbohydrate" },
  //   //   { "value": "28g", "unit": "Total Fat" },
  //   // ]
  // },

  // {
  //   "image": "https://balancegrille.s3.amazonaws.com/menu-images/snacks/Balance-Snack-Sweet-Potato-Samosas.webp",
  //   "title": "sweet potato samosas",
  //   "price": "$5",
  //   "flavorTags": [
  //     { "tag": "Lightly Spiced" },
  //     { "tag": "Sweet" }
  //   ],
  //   "description": "Available for a limited time, this sweet and lightly spiced treat is perfect as a dessert. Delicious pockets of sweet potatoes with marshmallows, butter, and spices. Lightly fried to golden perfection!",
  //   "descriptionNotes": " NOT Gluten Free, NOT Vegan, Contains Dairy.",
  //   "dietary": [
  //     { "type": "vegetarian", "title": "Vegetarian" },
  //   ],
  //   // "nutrition": [
  //   //   { "value": "310", "unit": "Calories" },
  //   //   { "value": "5g", "unit": "Protein" },
  //   //   { "value": "13g", "unit": "Total Carbohydrate" },
  //   //   { "value": "28g", "unit": "Total Fat" },
  //   // ]
  // },

  // {
  //   "image": "https://balancegrille.s3.amazonaws.com/menu-images/snacks/balance-snacks-curry-cauliflower.png",
  //   "title": "curry cauliflower",
  //   "price": "$6",
  //   "flavorTags": [
  //     { "tag": "Salty" },
  //     { "tag": "Smokey" },
  //     { "tag": "Savory" }
  //   ],
  //   "description": "Fresh cauliflower florets flash wok-fired and lightly charred with our signature curry seasoning. Served on a bed of red cabbage and topped with our very own Balance Farms micro kale. Served warm to cool, not hot, with an optional side of house made hot oil (upon request) for a touch of heat.",
  //   "dietary": [
  //     { "type": "vegan", "title": "Vegan" },
  //     { "type": "gluten-free", "title": "Gluten Free", }
  //   ],
  //   "nutrition": [
  //     { "value": "310", "unit": "Calories" },
  //     { "value": "5g", "unit": "Protein" },
  //     { "value": "13g", "unit": "Total Carbohydrate" },
  //     { "value": "28g", "unit": "Total Fat" },
  //   ]
  // },

  {
    "image": "https://balancegrille.s3.amazonaws.com/menu-images/snacks/bangbang-nachos.png",
    "title": "bangbang nachos",
    "price": "$5",
    "flavorTags": [
      { "tag": "Citrusy" },
      { "tag": "Salty" },
      { "tag": "Spicy 5/5" },
    ],
    "description": "Inspired by the Indian street food scene, this snack is served with housemade crispy flour chips, seasonal potatoes, chickpeas, tomatoes, red onions, and cilantro. BangBang sauce and yogurt is served on the side.",
    // "dietary": [
    //   { "type": "vegan", "title": "Vegan" },
    //   { "type": "gluten-free", "title": "Gluten Free", }
    // ],
    "nutrition": [
      { "value": "520", "unit": "Calories" },
      { "value": "9g", "unit": "Protein" },
      { "value": "56g", "unit": "Total Carbohydrate" },
      { "value": "29g", "unit": "Total Fat" },
    ]
  },

  {
    // "image": "https://balancegrille.s3.amazonaws.com/menu-images/snacks/balance-snack-edamame.webp",
    "image": "https://balancegrille.s3.amazonaws.com/menu-images/snacks/Tajin-Tango-Edamame.webp",
    "title": "edamame",
    "price": "$5",
    "flavorTags": [
      { "tag": "Nutty" },
      { "tag": "Salty" },
      { "tag": "Citrusy" },
    ],
    "description": "Imported baby soybeans in the pod, briefly steamed and served either with our original sea salt recipe, or our citrus tango mix for an upcharge ($.50).",
    "dietary": [
      { "type": "vegan", "title": "Vegan" },
      { "type": "gluten-free", "title": "Gluten Free", }
    ],
    "nutrition": [
      { "value": "350", "unit": "Calories" },
      { "value": "31g", "unit": "Protein" },
      { "value": "28g", "unit": "Total Carbohydrate" },
      { "value": "15g", "unit": "Total Fat" },
    ]
  },

  {
    "image": "https://balancegrille.s3.us-east-1.amazonaws.com/menu-images/snacks/balance-snacks-creamy-wontons.png",
    "title": "creamy wontons",
    "price": "$4.5",
    "flavorTags": [
      { "tag": "Creamy" },
      { "tag": "Crispy" },
      { "tag": "Tart" }
    ],
    "description": "Handmade cream cheese and vegetable wontons, served with our signature sweet & sour sauce. Choose between our classic recipe or our limited-time jalapeño flavor!",
    "descriptionNotes": "Contains dairy.",
    "dietary": [
      { "type": "vegetarian", "title": "Vegetarian" },
    ],
    "nutrition": [
      { "value": "530", "unit": "Calories" },
      { "value": "8g", "unit": "Protein" },
      { "value": "39g", "unit": "Total Carbohydrate" },
      { "value": "38g", "unit": "Total Fat" },
    ]
  },

  {
    "image": "https://balancegrille.s3.us-east-1.amazonaws.com/menu-images/snacks/citrus-brussels.png",
    "title": "citrus brussels",
    "price": "$5.5",
    "flavorTags": [
      { "tag": "Sweet" },
      { "tag": "Citrusy" },
      { "tag": "Tangy" }
    ],
    "description": "Split brussels sprouts wok-fired in a unique orange glaze. Perfectly balanced flavors in every bite!",
    "dietary": [
      { "type": "vegan", "title": "Vegan" },
      { "type": "gluten-free", "title": "Gluten Free" }
    ],
    "nutrition": [
      { "value": "450", "unit": "Calories" },
      { "value": "7g", "unit": "Protein" },
      { "value": "50g", "unit": "Total Carbohydrate" },
      { "value": "27g", "unit": "Total Fat" },
    ]
  },

  {
    "image": "https://balancegrille.s3.us-east-1.amazonaws.com/menu-images/snacks/five-spice-poppers.png",
    "title": "chicken poppers",
    "price": "$5.5",
    "flavorTags": [
      { "tag": "Sweet" },
      { "tag": "Savory" },
      { "tag": "Aromatic" }
    ],
    "description": "Fresh strips of juicy chicken thigh, hand-battered, fried, and spun in our signature sauces! Choose between our original five spice sauce or try our new citrus orange recipe.",
    // "descriptionNotes": "",
    "nutrition": [
      { "value": "750", "unit": "Calories" },
      { "value": "30g", "unit": "Protein" },
      { "value": "69g", "unit": "Total Carbohydrate" },
      { "value": "39g", "unit": "Total Fat" },
    ]
  },

  {
    "image": "https://balancegrille.s3.amazonaws.com/menu-images/snacks/balance-snacks-veggie-egg-roll.png",
    "title": "veggie eggroll",
    "price": "$3",
    "flavorTags": [
      { "tag": "Sweet" },
      { "tag": "Sour" },
      { "tag": "Spicy" }
    ],
    "description": "Back by popular demand, but this time in vegetarian version. It is served with our sweet & sour sauce, and hot mustard is offered for free upon request.",
    "dietary": [
      { "type": "vegetarian", "title": "Vegetarian" },
    ],
    "nutrition": [
      { "value": "150", "unit": "Calories" },
      { "value": "2g", "unit": "Protein" },
      { "value": "29g", "unit": "Total Carbohydrate" },
      { "value": "3g", "unit": "Total Fat" },
    ]
  },

]
